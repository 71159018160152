
import React, {useState} from "react"
import Layout from "../../../components/layout"

import WebappWidgetTable from "../../../components/webapps/widget/table";

const formatTools = require("../../../../lib/formatTools");

const mainEntity = "onsproduct";


const mainFields = [
	{"label":"Product", "dbfield": "onsproduct_name", "type": "text", "filtertype": "textbox"},
	{"label":"Last Order", "formula":"max(onsorderform_datetime)", "dbfield": "maxdate", "type": "datetime", "filtertype": "date"},
	{"label":"Earliest Order", "formula":"min(onsorderform_datetime)", "dbfield": "mindate", "type": "datetime", "filtertype": "date"},
	{"label":"Transactions", "formula":"count(distinct ONSORDERFORM.onsorderform_id)", "dbfield": "transaction", "type": "integer", "filtertype": "integer"},
	{"label":"Order Amount", "formula":"sum(ONSBOOKINGITEM.onsproduct_price*onsbookingitem_orderqty)", "dbfield": "amount", "type": "currency", "filtertype": "currency"},
	{"label":"Order QTY", "formula":"sum(onsbookingitem_orderqty)", "dbfield": "qty", "type": "integer", "filtertype": "integer"},
	{"label":"Served Amount", "formula":"sum(ONSBOOKINGITEM.onsproduct_price*onsbookingitem_deliverqty)", "dbfield": "servedamount", "type": "currency", "filtertype": "currency"},
	{"label":"Served QTY", "formula":"sum(onsbookingitem_deliverqty)", "dbfield": "servedqty", "type": "integer", "filtertype": "integer"},
];

const mainFilterFields = [
	{"label":"Product", "dbfield": "onsproduct_name", "type": "text", "filtertype": "textbox"},
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "date"},
	{"label":"Order Status", "dbfield": "ONSORDERFORM.onsorderformstatus_id", "type": "combo", "filtertype": "combo", "options": [
			{"value":1,"display":"Draft"},
			{"value":2,"display":"Ordered"},
			{"value":3,"display":"Prepared"},
			{"value":4,"display":"For Shipping"},
			{"value":5,"display":"Completed"},	// Delivered
			{"value":6,"display":"Cancelled"},
		]},

];

const ReportProductSalesPage = ({location}) => {
	const [token, setToken] = useState("");

	const defaultstartdate = formatTools.getDateStr(8-24*180); // ~6months ago


	const mainQueryparams = {
		tablelist: ["ONSORDERFORM", "ONSBOOKINGITEM"],
		//joinlist: [],
		grouping: {fieldlist:["ONSPRODUCT.onsproduct_id", "onsproduct_name"]},
		condlist: [
			"ONSPRODUCT.onsproduct_id=ONSBOOKINGITEM.onsproduct_id",
			"ONSORDERFORM.onsorderform_id=ONSBOOKINGITEM.onsorderform_id",
			"ONSORDERFORM.onsorderform_datetime>='"+defaultstartdate+"'"
		],
	}

	const tmpsearchstate = {
		"filter":[
			/*
			{
				"display":"Past 6 months",
				//"display":"Order date after '"+formatTools.datestr(defaultstartdate)+"'",
				"field":"onsorderform_datetime", "operation":" >= ","value":defaultstartdate},
			//{"display":"Order date before '7/1/2024'","field":"onsorderform_datetime", "operation":" < ","value":"2024-07-01"}
			*/
		],
		"orderbyfield": "qty",
		"orderbymode": "desc"

	};

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				<WebappWidgetTable
						title={"Sales by Product"}
						parentlink={{"title":"Reports", "url":"/reports"}}
						token={token}
						entity={mainEntity}
						fieldList={mainFields}
						filterfieldList={mainFilterFields}
						userparam={mainQueryparams}
						initsearchstate={tmpsearchstate}
						showControls={true}
						showDownload={true}
					/>
		</Layout>
}


export default ReportProductSalesPage;
